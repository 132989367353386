import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Label from 'reactstrap/lib/Label';
/* Utils */
import { EventToolbox } from 'client/utils/event-toolbox';
/* Constants */
import {
  DEACTIVATE_OPTION_OTHER,
  DEALER_DEACTIVATE_FORM,
} from 'site-modules/microsite/dealers/constants/digital-checkout';
import { DIGITAL_CHECKOUT_EVENTS } from 'site-modules/shared/constants/financing/digital-checkout-events';
/* Components */
import { FadeInSlideUpOnScroll } from 'site-modules/shared/components/animated/fade-in-slide-up';
import { RadioButton } from 'site-modules/shared/components/radio-button/radio-button';

import './deactivation-survey.scss';

export const DeactivationSurveyUI = ({ surveyHeader, surveyThankYouText, options, isMobile }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [openTextField, setOpenTextField] = useState(false);
  const [otherOptionValue, setOtherOptionValue] = useState('');

  const fireSubmitSurveyTracking = value => {
    EventToolbox.fireCustomEvent(DIGITAL_CHECKOUT_EVENTS.SUBMIT_SURVEY, {
      creativeId: DEALER_DEACTIVATE_FORM,
      value,
    });
  };

  const handleSubmit = () => {
    fireSubmitSurveyTracking(otherOptionValue);
    setShowSuccess(true);
  };
  const handleChange = ({ target: { value } }) => {
    setSelectedOption(value);

    if (value === DEACTIVATE_OPTION_OTHER) {
      setOpenTextField(true);
    } else {
      const currentOption = options.find(option => option.id === value);
      const { copy } = currentOption.getAllMetadata();
      fireSubmitSurveyTracking(copy);
      setShowSuccess(true);
    }
  };
  const handleTextInputChange = e => {
    setOtherOptionValue(e.target.value);
  };

  return (
    <div
      className={classNames('deactivation-survey-wrapper rounded-8 bg-white py-1_5 px-1 px-md-1_5 mt-1 mt-md-2', {
        'd-flex align-items-center': showSuccess,
      })}
      data-tracking-parent={DEALER_DEACTIVATE_FORM}
    >
      {showSuccess ? (
        <FadeInSlideUpOnScroll className="mx-auto">
          <div className="fw-bold text-cool-gray-30">
            <span>{surveyThankYouText}</span>
          </div>
        </FadeInSlideUpOnScroll>
      ) : (
        <>
          <h3 className="text-start size-20 fw-medium text-cool-gray-30">{surveyHeader}</h3>
          <ul className="list-unstyled m-0">
            {options.map((option, index) => {
              const { copy } = option.getAllMetadata();
              const id = get(option, 'id');
              const isOtherOption = id === DEACTIVATE_OPTION_OTHER;
              return (
                <li
                  key={`option_${index + 1}`}
                  className={classNames(
                    'pos-r rounded px-1 py-0_25 text-gray-darker text-start option bg-cool-gray-90 mt-1',
                    { 'd-flex align-items-center justify-content-between': isOtherOption && !isMobile }
                  )}
                >
                  <Label className="m-0 py-0_25 pl-1_25">
                    <RadioButton handleChange={handleChange} id={id} isChecked={id === selectedOption} />
                    <span>{copy}</span>
                  </Label>
                  {index === options.length - 1 && openTextField && (
                    <div className={classNames('ml-1_25 ml-md-0', { 'd-flex w-100': !isMobile })}>
                      <Input
                        className="px-0_75 py-0_25 mx-md-0_75 my-0_5 my-md-0 size-16 text-field border-0 text-cool-gray-60"
                        maxLength={150}
                        onChange={handleTextInputChange}
                        placeholder="Please explain"
                        value={otherOptionValue}
                      />
                      <Button
                        className="px-0_75 mb-0_25 mb-md-0 text-capitalize size-16 fw-normal submit-btn"
                        color="blue-50"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

DeactivationSurveyUI.propTypes = {
  surveyThankYouText: PropTypes.string,
  surveyHeader: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  isMobile: PropTypes.bool,
};

DeactivationSurveyUI.defaultProps = {
  surveyThankYouText: '',
  surveyHeader: '',
  options: [],
  isMobile: false,
};
export const mapStateToProps = ({ mobile }) => ({
  isMobile: mobile,
});

export const DeactivationSurvey = connect(mapStateToProps)(DeactivationSurveyUI);
