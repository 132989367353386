import React from 'react';
import PropTypes from 'prop-types';

export const FormErrorMessage = ({ message, id }) =>
  !!message && (
    <p id={id} className="error-message text-start mb-0_75 size-12 ">
      {message}
    </p>
  );

FormErrorMessage.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
};

FormErrorMessage.defaultProps = {
  id: '',
  message: '',
};
