import React from 'react';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import Col from 'reactstrap/lib/Col';
import { CmsEntities } from 'client/data/models/cms';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

import './digital-checkout-pricing-and-fee.scss';

export const DigitalCheckoutPricingAndFee = ({ entry }) => {
  const metadata = entry.getAllMetadata();
  const entries = entry.children();

  if (isEmpty(metadata) && isEmpty(entries)) return null;
  const { disclaimer } = metadata;

  return (
    <Col xs={12} md={8} className="pricing-fee-wrapper bg-white p-2">
      {entries.map((item, index) => {
        const { title, explanationMarkTitle } = item.getAllMetadata();
        const bullets = item.children();
        return (
          <div key={`pricing-fee-item_${index + 1}`} className="mb-1_5">
            <h3 className="size-20 fw-bold text-center">
              {title}
              {explanationMarkTitle && (
                <span className="explanation-mark ml-0_25 text-white size-12 bg-blue-60 d-inline-block text-center">
                  {explanationMarkTitle}
                </span>
              )}
            </h3>
            {bullets.map((bullet, i) => {
              const { name, value, explanationMark, coloredValue } = bullet.getAllMetadata();
              return (
                <div
                  className="d-flex justify-content-between py-0_25 pricing-fee-list-item mb-0_5"
                  key={`pricing-fee-item-bullet_${i + 1}`}
                >
                  <div>
                    <ContentFragment classes="d-inline-block">{name}</ContentFragment>
                    {explanationMark && (
                      <span className="explanation-mark ml-0_25 text-white size-12 bg-blue-60 d-inline-block text-center">
                        {explanationMark}
                      </span>
                    )}
                  </div>
                  <div className={classnames({ 'text-green-40 fw-bold': coloredValue })}>{value}</div>
                </div>
              );
            })}
          </div>
        );
      })}
      <div className="size-12">{disclaimer}</div>
    </Col>
  );
};

DigitalCheckoutPricingAndFee.propTypes = {
  entry: CmsEntities.Content.isRequired,
};
