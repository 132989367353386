import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
/* Components */
import { FadeInSlideUpOnScroll } from 'site-modules/shared/components/animated/fade-in-slide-up';
import { DealerCenterCarLogo } from 'site-modules/microsite/dealers/components/dealer-center-car-logo/dealer-center-car-logo';
import { DeactivationSurvey } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/deactivation-survey/deactivation-survey';

export const SuccessMessage = ({
  thankYouHeader,
  thankYouText,
  surveyThankYouText,
  surveyHeader,
  showSurvey,
  surveyOptions,
  hideCarLogo,
}) => (
  <div className="mx-auto">
    <FadeInSlideUpOnScroll>
      <h2 className="size-36 fw-bold text-cool-gray-10 mb-2">{thankYouHeader}</h2>
    </FadeInSlideUpOnScroll>
    <FadeInSlideUpOnScroll>
      <div className={classNames('size-20 text-cool-gray-10', { 'mx-md-1 px-md-3_5': !showSurvey })}>
        {thankYouText}
      </div>
    </FadeInSlideUpOnScroll>
    <FadeInSlideUpOnScroll>
      {showSurvey ? (
        <DeactivationSurvey
          options={surveyOptions}
          surveyThankYouText={surveyThankYouText}
          surveyHeader={surveyHeader}
        />
      ) : (
        !hideCarLogo && <DealerCenterCarLogo />
      )}
    </FadeInSlideUpOnScroll>
  </div>
);

SuccessMessage.propTypes = {
  thankYouHeader: PropTypes.string.isRequired,
  thankYouText: PropTypes.string.isRequired,
  surveyThankYouText: PropTypes.string,
  surveyHeader: PropTypes.string,
  showSurvey: PropTypes.bool,
  surveyOptions: PropTypes.arrayOf(PropTypes.shape({})),
  hideCarLogo: PropTypes.bool,
};

SuccessMessage.defaultProps = {
  surveyThankYouText: '',
  surveyHeader: '',
  showSurvey: false,
  surveyOptions: [],
  hideCarLogo: false,
};
