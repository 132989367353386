import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get, isNull, isUndefined, isEqual } from 'lodash';
import { connect } from 'react-redux';
import Container from 'reactstrap/lib/Container';
/* Utils */
import { getQuery } from 'client/utils/location';
import { logger } from 'client/utils/isomorphic-logger';
import { EngagementHandler } from 'client/engagement-handlers';
import { DigitalCheckoutEngagementHandler } from 'client/engagement-handlers/digital-checkout-engagement-handler/digital-checkout-engagement-handler';
import { EventToolbox } from 'client/utils/event-toolbox';
import { enrollDealer } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/utils/digital-checkout-enrollment';
import {
  DEALER_ENROLL_FORM_BACKGROUND,
  DEALER_ENROLL_FORM_BACKGROUND_MOBILE,
} from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/utils/digital-checkout-backgrounds';
/* Models */
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { CmsEntities } from 'client/data/models/cms';
import { DigitalCheckoutModel, PATHS } from 'client/data/models/digital-checkout';
import { VenomVersion } from 'client/data/models/version';
/* Constants */
import { SCROLL_TO_ENROLLMENT_FORM } from 'site-modules/shared/constants/financing/dealer-enrollment-page';
import { DEALER_STATUS, STATUS_UNKNOWN } from 'site-modules/shared/constants/financing/digital-checkout';
import { DIGITAL_CHECKOUT_EVENTS } from 'site-modules/shared/constants/financing/digital-checkout-events';
import { DEACTIVATE_MODE, FORMS_MAP } from 'site-modules/microsite/dealers/constants/digital-checkout';

import './digital-checkout-activation-form.scss';

EngagementHandler.register(DigitalCheckoutEngagementHandler);

export const DigitalCheckoutActivationFormUI = ({ entry, pageName, dealer, venomVersion, isMobile, location }) => {
  const [isDeactivated, setIsDeactivated] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [dealerData, setDealerData] = useState(null);
  const rooftopId = get(getQuery(location), 'rooftopId');
  const dealerStatus = get(dealer, 'dealerStatus', STATUS_UNKNOWN);
  const FormComponent = get(FORMS_MAP[dealerStatus], 'component');

  useEffect(() => {
    if ((!isNull(dealer) && !isEqual(dealer, dealerData)) || isUndefined(rooftopId)) {
      EventToolbox.fireCustomEvent(DIGITAL_CHECKOUT_EVENTS.DEALER_STATUS, {
        dealerStatus: DEALER_STATUS[get(dealer, 'dealerStatus', STATUS_UNKNOWN)],
        rooftopId,
      });
      if (isUndefined(rooftopId)) {
        setDealerData({});
      } else {
        setDealerData(dealer);
      }
    }
  }, [dealer, dealerData, rooftopId]);

  const metadata = entry.getAllMetadata();

  const handleSubmit = async ({ trackingData, mode, fields }) => {
    EventToolbox.fireCustomEvent(DIGITAL_CHECKOUT_EVENTS.SUBMIT_ATTEMPTS, {
      ...trackingData,
      rooftopId,
    });
    try {
      await enrollDealer({ dealer, pageName, venomVersion, fields, mode });

      EventToolbox.fireCustomEvent(DIGITAL_CHECKOUT_EVENTS.ENROLL_STATUS, {
        isSuccess: true,
        creativeId: get(trackingData, 'creativeId'),
        rooftopId,
      });

      setShowSuccess(true);
      setIsDeactivated(mode === DEACTIVATE_MODE);
    } catch {
      logger('error', `Caramel API error`);
      EventToolbox.fireCustomEvent(DIGITAL_CHECKOUT_EVENTS.ENROLL_STATUS, {
        creativeId: get(trackingData, 'creativeId'),
        rooftopId,
      });
    }
  };

  if (isEmpty(metadata) || isNull(dealerData)) return null;

  const entries = entry.children();
  const currentEntry = entries.find(item => item.id === dealerStatus);
  const { header } = currentEntry.getAllMetadata();

  return (
    <div className="pos-r pt-md-2">
      <figure className="pos-a w-100 m-0">
        {isMobile ? DEALER_ENROLL_FORM_BACKGROUND_MOBILE : DEALER_ENROLL_FORM_BACKGROUND}
      </figure>
      <Container
        className="digital-checkout-activation-form-module pos-r mb-md-2_5 pt-3_5"
        id={SCROLL_TO_ENROLLMENT_FORM}
      >
        <h2 className="mt-3_5 text-center text-white size-36 fw-bold mb-1_25">{header}</h2>
        <FormComponent
          entry={currentEntry}
          dealer={dealerData}
          location={location}
          handleSubmit={handleSubmit}
          showSuccess={showSuccess}
          isDeactivated={isDeactivated}
        />
      </Container>
    </div>
  );
};

DigitalCheckoutActivationFormUI.propTypes = {
  entry: CmsEntities.Content.isRequired,
  pageName: PropTypes.string.isRequired,
  dealer: PropTypes.shape({}),
  venomVersion: PropTypes.string,
  isMobile: PropTypes.bool,
  location: PropTypes.shape({}),
};

DigitalCheckoutActivationFormUI.defaultProps = {
  dealer: null,
  venomVersion: '',
  isMobile: false,
  location: {},
};

export const mapStateToProps = ({ mobile }) => ({
  isMobile: mobile,
});

const stateToPropsConfig = {
  dealer: bindToPath(
    ({ location }) => PATHS.DEALERS({ rooftopId: get(getQuery(location), 'rooftopId') }),
    DigitalCheckoutModel
  ),
  venomVersion: bindToPath('version', VenomVersion),
};

export const DigitalCheckoutActivationForm = connect(mapStateToProps)(
  connectToModel(DigitalCheckoutActivationFormUI, stateToPropsConfig)
);
