import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import Container from 'reactstrap/lib/Container';
import Button from 'reactstrap/lib/Button';
/* Models */
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { CmsEntities } from 'client/data/models/cms';
import { DigitalCheckoutModel, PATHS } from 'client/data/models/digital-checkout';
/* Utils */
import { renderEntries } from 'client/site-modules/microsite/dealers/components/dealer-center-digital-checkout/utils/digital-checkout-entries-renderer';
import { getQuery } from 'client/utils/location';
import { scrollToElement } from 'client/utils/scroll';
import { EventToolbox } from 'client/utils/event-toolbox';
import { DIGITAL_CHECKOUT_EVENTS } from 'site-modules/shared/constants/financing/digital-checkout-events';
import { scrollToForm } from 'client/site-modules/microsite/dealers/components/dealer-center-digital-checkout/utils/digital-checkout-helper';
/* Constants */
import { SCROLL_TO_PAYMENT_TABLE } from 'site-modules/shared/constants/financing/dealer-enrollment-page';
import { STATUS_ACTIVATED } from 'site-modules/shared/constants/financing/digital-checkout';

import './dealer-center-digital-checkout.scss';

export const DealerCenterDigitalCheckoutUI = ({ entry, pageName, location, dealer }) => {
  const [dealerStatus, setDealerStatus] = useState('');
  const metadata = entry.getAllMetadata();

  useEffect(() => {
    const handleClick = e => {
      const { target } = e;
      if (target && target.classList.contains('scroll-to-payment')) {
        scrollToElement(SCROLL_TO_PAYMENT_TABLE);

        const creativeId = target.closest('[data-tracking-parent]')?.dataset.trackingParent;
        const value = target.innerText;
        EventToolbox.fireCustomEvent(DIGITAL_CHECKOUT_EVENTS.DETAILS, { creativeId, value });
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(dealer)) {
      setDealerStatus(get(dealer, 'dealerStatus', ''));
    }
  }, [dealer]);

  if (isEmpty(metadata)) return null;

  const { header, subheader, ctaText } = metadata;
  const dealerName = get(dealer, 'dealerName', '');

  return (
    <div className="digital-checkout-wrapper">
      {dealerStatus === STATUS_ACTIVATED && (
        <div className="welcome-container">
          <Container className="pt-1_5 pb-1">
            <h2 className="size-36 text-white mb-1">
              {header} {dealerName}!
            </h2>
            <div className="text-white size-20">
              {subheader}
              <Button
                className="fw-bold text-white text-capitalize size-20 pl-0_25 pb-0_25  pt-0 pr-0"
                color="link"
                onClick={scrollToForm}
              >
                {ctaText}
                <i className="icon-arrow-right3 size-14" aria-hidden />
              </Button>
            </div>
          </Container>
        </div>
      )}
      {renderEntries({ entriesToRender: entry.children(), pageName, location, dealerStatus })}
    </div>
  );
};

DealerCenterDigitalCheckoutUI.propTypes = {
  entry: CmsEntities.Content.isRequired,
  pageName: PropTypes.string.isRequired,
  location: PropTypes.shape({}),
  dealer: PropTypes.shape({}),
};

DealerCenterDigitalCheckoutUI.defaultProps = {
  location: {},
  dealer: {},
};

const stateToPropsConfig = {
  dealer: bindToPath(
    ({ location }) => PATHS.DEALERS({ rooftopId: get(getQuery(location), 'rooftopId') }),
    DigitalCheckoutModel
  ),
};

export const DealerCenterDigitalCheckout = connectToModel(DealerCenterDigitalCheckoutUI, stateToPropsConfig);
