import React from 'react';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { CmsEntities } from 'client/data/models/cms';

import './digital-checkout-explanations.scss';

export const DigitalCheckoutExplanations = ({ entry }) => {
  const entries = entry.children();

  if (isEmpty(entries)) return null;

  return (
    <Col xs={12} md={4} className="bg-cool-gray-90 explanations-wrapper p-2">
      <ol className="explanation-list text-cool-gray-30 p-0">
        {entries.map((item, index) => {
          const { title, description } = item.getAllMetadata();
          const bullets = item.children();

          return (
            <li className={classnames('d-flex', { 'mt-1_5': index > 0 })} key={`explanation-item_${index + 1}`}>
              <span className="circle-number bg-blue-60 fw-bold size-12 mr-0_75 mt-0_25 text-white text-center">
                {index + 1}
              </span>
              <div>
                <div className="fw-bold">{title}</div>
                <div>{description}</div>
                {!isEmpty(bullets) && (
                  <ul className="p-0 list-unstyled mt-0_75">
                    {bullets.map((bullet, i) => {
                      const { name, value } = bullet.getAllMetadata();
                      return (
                        <li key={`explanation-bullet${i + 1}`}>
                          <Row>
                            <Col xs={3} className="d-flex justify-content-between align-items-baseline">
                              <span>{value}</span>
                              <i className="icon-arrow-right8 size-10" aria-hidden />
                            </Col>
                            <Col xs={9}>{name}</Col>
                          </Row>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </Col>
  );
};

DigitalCheckoutExplanations.propTypes = {
  entry: CmsEntities.Content.isRequired,
};
