import React, { Fragment, useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { isEmpty, get } from 'lodash';
import { getQuery } from 'client/utils/location';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { CmsEntities } from 'client/data/models/cms';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import { FadeInSlideUpOnScroll } from 'site-modules/shared/components/animated/fade-in-slide-up';
import { FormField } from 'site-modules/shared/components/form-field/form-field';
import { formValidation } from 'site-modules/shared/components/form-validation/form-validation';
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { LEAD_FORM_CREATIVE_ID } from 'site-modules/microsite/dealers/constants/page';
import { SuccessMessage } from 'site-modules/microsite/dealers/components/dealer-center-lead-form/success-message/success-message';
import { DealerCenterButton } from 'site-modules/microsite/dealers/components/dealer-center-button/dealer-center-button';

import './dealer-center-lead-form.scss';

// These values are copied over from what is currently implemented in the industry center lead form.
export const FORM_FIELDS = {
  name: '00N0f00000FtQSs',
  dealerName: '00NG000000DhLpG',
  phoneNumber: '00NG000000Els9f',
  zipCode: '00NG000000Els9h',
  email: '00NG000000Els9c',
  survey: 'description',
  orgid: '00DA0000000IlZt',
  ref: '00N0f00000FtQSq',
  recordType: '012G0000001IonD',
};

const SURVEY_REFERRAL_SOURCES = [
  'LinkedIn',
  'X (Twitter)',
  'Instagram or Facebook',
  'Car Dealership Guy',
  'Email',
  'YouTube',
  'Streaming TV',
  'News media',
  'Peer referral',
  'Edmunds sales rep',
  'Other',
];

const validators = {
  [FORM_FIELDS.name]: {
    test: validation.validateName,
    errorText: 'Please correct your name. Special characters cannot be included.',
  },
  [FORM_FIELDS.dealerName]: {
    test: field => validation.validateMinLength(field, 4),
    errorText: 'Please correct your dealer name.',
  },
  [FORM_FIELDS.phoneNumber]: {
    test: validation.validatePhoneNumber,
    errorText: 'Please enter a valid phone number.',
  },
  [FORM_FIELDS.zipCode]: {
    test: validation.validateZip,
    errorText: 'Please enter a valid ZIP code.',
  },
  [FORM_FIELDS.email]: {
    test: validation.validateEmail,
    errorText: 'Please enter a valid email.',
  },
};

export const FormErrorMessage = ({ message }) =>
  !!message && <p className="error-message text-start mb-0_75 size-12">{message}</p>;

FormErrorMessage.propTypes = {
  message: PropTypes.string,
};

FormErrorMessage.defaultProps = {
  message: '',
};

const DEALER_MICROSITE_URL = 'https://dealers.edmunds.com';

const generateDomain = location => location?.pathname && `${DEALER_MICROSITE_URL}${location.pathname}`;

export const DealerCenterLeadFormUI = ({ entry, validationErrors, validate, fields, fieldRef, location, pageName }) => {
  const [locationHref, setLocationHref] = useState('');
  const [isSelectTouched, setIsSelectTouched] = useState(false);
  const showSuccess = useMemo(() => {
    const query = getQuery(location);
    const submitted = get(query, 'submitted', false);
    return submitted;
  }, [location]);

  const metadata = entry.getAllMetadata();

  useEffect(() => {
    if (window) {
      setLocationHref(get(window, 'location.href', ''));
    }
  }, []);

  /* istanbul ignore next */
  const selectOnChange = useCallback(() => setIsSelectTouched(true), [setIsSelectTouched]);

  const handleSubmit = useCallback(
    e => {
      const isValid = validate();
      if (!isValid) {
        e.preventDefault();
      } else {
        const dealerName = fields[FORM_FIELDS.dealerName].value();

        EventToolbox.fireTrackAction({
          event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
          event_data: {
            creative_id: LEAD_FORM_CREATIVE_ID,
            action_name: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
            action_category: TrackingConstant.USER_ACTION_CATEGORY,
            action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
            subaction_name: TrackingConstant.SUBACTION_SUBMIT_INQUIRY,
            value: `${pageName} - ${dealerName}`,
          },
        });
      }
    },
    [validate, fields, pageName]
  );

  if (isEmpty(metadata)) return null;

  const { thankYouHeader, thankYouText, formSubmission, formHeader, formText, ctaText } = metadata;

  return (
    <div className="dealer-center-lead-form-module pos-r mb-2_5" data-tracking-parent={LEAD_FORM_CREATIVE_ID}>
      <Container className="px-1_25 px-sm-0">
        <Row className="text-white text-center justify-content-center no-gutters">
          <Col>
            {showSuccess ? (
              <SuccessMessage thankYouHeader={thankYouHeader} thankYouText={thankYouText} />
            ) : (
              <Form
                noValidate
                onSubmit={handleSubmit}
                method="POST"
                action={formSubmission}
                data-testid="dealer-center-lead-form"
                className="dealer-center-lead-form"
              >
                <FadeInSlideUpOnScroll>
                  <ContentFragment classes="title heading-2 text-white mb-1">{formHeader}</ContentFragment>
                </FadeInSlideUpOnScroll>
                <FadeInSlideUpOnScroll>
                  <ContentFragment classes="sub-title text-white mx-auto mb-3_5">{formText}</ContentFragment>
                </FadeInSlideUpOnScroll>
                <FadeInSlideUpOnScroll>
                  <FormGroup className="form-group-container bg-blue-100 my-0 mx-auto p-1_25">
                    <FormField
                      id={FORM_FIELDS.name}
                      maxLength={255}
                      name={FORM_FIELDS.name}
                      labelClassName="float-left size-12"
                      size={16}
                      label="Name"
                      type="text"
                      placeholder="Name"
                      ref={field => fieldRef(FORM_FIELDS.name, field)}
                      isValid={!(FORM_FIELDS.name in validationErrors)}
                      onBlur={validate}
                    />
                    <FormErrorMessage message={validationErrors[FORM_FIELDS.name]} />
                    <FormField
                      id={FORM_FIELDS.dealerName}
                      maxLength={255}
                      name={FORM_FIELDS.dealerName}
                      labelClassName="float-left size-12"
                      size={16}
                      label="Dealer Name"
                      type="text"
                      placeholder="Dealer Name"
                      ref={field => fieldRef(FORM_FIELDS.dealerName, field)}
                      isValid={!(FORM_FIELDS.dealerName in validationErrors)}
                      onBlur={validate}
                    />
                    <FormErrorMessage message={validationErrors[FORM_FIELDS.dealerName]} />
                    <Row>
                      <Col xs={6}>
                        <FormField
                          id={FORM_FIELDS.phoneNumber}
                          maxLength={40}
                          name={FORM_FIELDS.phoneNumber}
                          labelClassName="float-left size-12"
                          size={16}
                          label="Phone"
                          type="text"
                          placeholder="Phone"
                          ref={field => fieldRef(FORM_FIELDS.phoneNumber, field)}
                          isValid={!(FORM_FIELDS.phoneNumber in validationErrors)}
                          onBlur={validate}
                        />
                        <FormErrorMessage message={validationErrors[FORM_FIELDS.phoneNumber]} />
                      </Col>
                      <Col xs={6}>
                        <FormField
                          id={FORM_FIELDS.zipCode}
                          maxLength={10}
                          name={FORM_FIELDS.zipCode}
                          labelClassName="float-left size-12"
                          size={16}
                          label="ZIP"
                          type="text"
                          placeholder="ZIP"
                          ref={field => fieldRef(FORM_FIELDS.zipCode, field)}
                          isValid={!(FORM_FIELDS.zipCode in validationErrors)}
                          onBlur={validate}
                        />
                        <FormErrorMessage message={validationErrors[FORM_FIELDS.zipCode]} />
                      </Col>
                    </Row>
                    <FormField
                      id={FORM_FIELDS.email}
                      maxLength={80}
                      size={16}
                      name={FORM_FIELDS.email}
                      labelClassName="float-left size-12"
                      placeholder="Email"
                      ref={field => fieldRef(FORM_FIELDS.email, field)}
                      label="Email"
                      type="text"
                      isValid={!(FORM_FIELDS.email in validationErrors)}
                      onBlur={validate}
                    />
                    <FormErrorMessage message={validationErrors[FORM_FIELDS.email]} />
                    <Label for={FORM_FIELDS.survey} className="float-left size-12">
                      How did you initially hear about Edmunds?
                    </Label>
                    <div className="styled-select-container">
                      <div className="styled-select-chevron">
                        <Input
                          className={classnames('styled-select', { untouched: !isSelectTouched })}
                          id={FORM_FIELDS.survey}
                          name={FORM_FIELDS.survey}
                          type="select"
                          onChange={selectOnChange}
                          defaultValue=""
                        >
                          <option value="" disabled hidden>
                            Select a response
                          </option>
                          {SURVEY_REFERRAL_SOURCES.map(item => (
                            <option key={item}>{item}</option>
                          ))}
                        </Input>
                      </div>
                    </div>
                    <DealerCenterButton
                      color="blue"
                      ctaText={ctaText}
                      className="fw-bold text-uppercase mt-2_5"
                      block
                      type="Submit"
                      id="dealer-center-lead-form-submit"
                    />
                  </FormGroup>
                </FadeInSlideUpOnScroll>
                <Fragment>
                  <input type="hidden" name="orgid" value={FORM_FIELDS.orgid} />
                  <input type="hidden" name="retURL" value={`${locationHref}?submitted=true`} />
                  <input type="hidden" name="recordType" id="recordType" value={FORM_FIELDS.recordType} />
                  <input
                    id={FORM_FIELDS.ref}
                    value={generateDomain(location)}
                    maxLength={255}
                    name={FORM_FIELDS.ref}
                    size={20}
                    type="hidden"
                  />
                </Fragment>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

DealerCenterLeadFormUI.propTypes = {
  entry: CmsEntities.Content.isRequired,
  validationErrors: PropTypes.objectOf(PropTypes.string),
  validate: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    [FORM_FIELDS.dealerName]: PropTypes.shape({
      value: PropTypes.func,
    }),
  }).isRequired,
  fieldRef: PropTypes.func.isRequired,
  location: PropTypes.shape({}),
  pageName: PropTypes.string.isRequired,
};

DealerCenterLeadFormUI.defaultProps = {
  validationErrors: {},
  location: {},
};

export const DealerCenterLeadForm = withRouter(formValidation(DealerCenterLeadFormUI, validators));
